import platformApi from 'api/platformApi/platformApi';
import { IWorkspacePipelinesParamsQuery, IWorkspacePipelinesResponse } from './types';

/** Retrieves workspace-level pipelines from Pathways */
export const getWorkspacePipelines = async ({
  params: {
    query: { workspaceId },
  },
}: ServiceFnConfig<undefined, IWorkspacePipelinesParamsQuery>) => {
  const axiosResponse = await platformApi.get<IWorkspacePipelinesResponse>(
    `${import.meta.env.PATHWAYS_API_BASE_URL}/pipelines`,
    {
      params: { workspaceId },
    },
  );
  return axiosResponse.data.items;
};
