import { skipToken, useQuery } from '@tanstack/react-query';
import { getWorkspacePipelines } from './services';

interface UseWorkspacePipelinesParams {
  workspaceId: string;
}

export const getWorkspacePipelinesQueryKey = (params: UseWorkspacePipelinesParams) => [
  'workspaces',
  params.workspaceId,
  'pipelines',
];

const useWorkspacePipelines = (params: UseWorkspacePipelinesParams) => {
  const { workspaceId } = params;

  return useQuery({
    queryKey: getWorkspacePipelinesQueryKey(params),
    queryFn: workspaceId
      ? () => getWorkspacePipelines({ params: { query: { workspaceId } } })
      : skipToken,
  });
};

export default useWorkspacePipelines;
